html, body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
}

h1 {
  font-weight: 100;
}

h2 {
  font-weight: 100;
}

h3 {
  font-weight: 100;
}

div {
  font-weight: 100;
}

p {
  font-weight: 100;
}